/* You can add global styles to this file, and also import other style files */

html {
   background-color: rgb(43, 41, 41);
  /* color: white; */
  font-family: 'Courier New', Courier, monospace;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.chartContainer canvas {
}
